.footer-layout {
  position: absolute;
  height: 30px;
  text-align: center;
}

html,
body,
#root,
.page-wrapper,
.body-wrapper {
  height: 100%;
}

.wrap {
  white-space: nowrap;
  vertical-align: middle;
}

.layout-scan {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  max-height: 400px;
}

.rdw-editor-main {
  min-height: 300px !important;
}